import React, { useMemo } from 'react'
import flame from 'assets/icons/flame.svg'
import dayjs from 'dayjs'

type Props = {
  completedCount: number
  commitment: number
  startDate: string
}

export const FlexGoal = ({ commitment, completedCount, startDate }: Props) => {
  const renderCompletedFlames = () => {
    const flames = []
    for (let i = 0; i < completedCount; i++) {
      flames.push(
        <svg key={`${i}svgcomplete`} width="16" height="21" viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg" className="fill-coral w-8 h-8">
          <path d="M10.0553 8.43712C10.0553 8.43712 10.9836 2.95296 7.18192 0.571289C7.06759 2.47738 6.14688 4.24456 4.65026 5.43046C3.02442 6.85962 -0.0322429 10.0713 0.000257102 13.4963C-0.0215285 16.4831 1.65097 19.2243 4.31692 20.5713C4.41126 19.2359 5.03933 17.9948 6.05942 17.128C6.92408 16.4633 7.48531 15.4789 7.61692 14.3963C9.89313 15.606 11.3655 17.9235 11.4936 20.498V20.5146C14.0068 19.3609 15.6607 16.8952 15.7744 14.1321C16.0444 10.913 14.2811 6.53879 12.7178 5.10879C12.1276 6.42651 11.2112 7.57202 10.0553 8.43712Z" />
        </svg>,
      )
    }
    return flames
  }

  const renderRemainingFlames = () => {
    const flames = []
    for (let i = 0; i < commitment - completedCount; i++) {
      flames.push(
        <svg key={`${i}svg`} width="16" height="21" viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg" className="fill-slate-500 w-8 h-8 p-0.5">
          <path d="M10.0553 8.43712C10.0553 8.43712 10.9836 2.95296 7.18192 0.571289C7.06759 2.47738 6.14688 4.24456 4.65026 5.43046C3.02442 6.85962 -0.0322429 10.0713 0.000257102 13.4963C-0.0215285 16.4831 1.65097 19.2243 4.31692 20.5713C4.41126 19.2359 5.03933 17.9948 6.05942 17.128C6.92408 16.4633 7.48531 15.4789 7.61692 14.3963C9.89313 15.606 11.3655 17.9235 11.4936 20.498V20.5146C14.0068 19.3609 15.6607 16.8952 15.7744 14.1321C16.0444 10.913 14.2811 6.53879 12.7178 5.10879C12.1276 6.42651 11.2112 7.57202 10.0553 8.43712Z" />
        </svg>,
      )
    }
    return flames
  }

  const reachedGoal = useMemo(() => completedCount >= commitment, [completedCount, commitment])
  return (
    <div className="relative w-full flex justify-around">
      <div className="grid grid-cols-3 rounded-xl p-2 bg-slate-100">
        <div className="flex items-center justify-center  col-span-1 mr-2">
          {renderCompletedFlames()}
          {renderRemainingFlames()}
        </div>
        {reachedGoal && (
          <div className="col-span-2 text-center ">
            <h3 className="text-md font-bold">LEANMAZING!</h3>
            <p className="text-sm">You&apos;ve completed your weekly commitment!</p>
          </div>
        )}
        {!reachedGoal && (
          <div className="col-span-2 ">
            <h3 className="text-md font-bold">Our goal is {commitment} workouts this week!</h3>
            <p className="text-sm">Finish them all in days to continue your Lean Streak!</p>
          </div>
        )}
      </div>
    </div>
  )
}
