import React, { useEffect, useState, useRef } from 'react'
import * as UCServices from '../../../services/api/userChallenges'
import moment from 'moment'

import styles from './ProgressBar.module.scss'
import { useQuery } from 'react-query'
// import { isFlexChallenge } from 'shared/functions/utils'
// import { currentWeek } from 'services/api/userChallenges'
// import { ActiveChallenge, Challenge } from '../../../types/index'
import { UserChallengeWithRelatedData } from 'services/api/types'

type Props = {
  currentUserChallenge: UserChallengeWithRelatedData
  size: number
  // progress: number
  strokeWidth: number
  tz: string
}

export const ProgressBar: React.FC<Props> = ({ currentUserChallenge, size, strokeWidth, tz }) => {
  // Fetch the current week data if the user challenge is available
  const {
    data: currentWeekData,
    isLoading: currentWeekLoading,
    error: currentWeekError,
  } = useQuery<number>(['currentWeek', currentUserChallenge?.id], () => UCServices.currentWeek(currentUserChallenge?.id as string), {
    staleTime: Infinity,
    enabled: !!currentUserChallenge?.id,
    retry: false,
  })
  const [offset, setOffset] = useState(0)
  const circleRef = useRef<SVGCircleElement | null>(null)

  const center = size / 2
  const radius = size / 2 - strokeWidth / 2
  const circumference = 2 * Math.PI * radius

  // Update progress bar offset based on flex or non-flex challenge
  useEffect(() => {
    if (currentWeekLoading || currentWeekError || currentWeekData == null || !currentUserChallenge) return

    let progressOffset = 0
    let progress = 0

    const challenge = currentUserChallenge.challenge

    if (currentUserChallenge.weeklyCommitment && currentUserChallenge.weeklyCommitment > 0 && challenge && challenge.numberOfWeeks) {
      // Flex challenge: calculate progress based on weeks
      progress = currentWeekData / challenge.numberOfWeeks
    } else if (currentUserChallenge.challengeWorkouts.length > 0) {
      // Non-flex challenge: calculate progress based on total workouts
      const totalDays = currentUserChallenge.challengeWorkouts.length
      progress = Math.min(currentWeekData / totalDays, 1) // Ensure progress doesn't exceed 100%
    }

    // Calculate stroke offset
    progressOffset = circumference - progress * circumference
    setOffset(progressOffset)
  }, [currentWeekData, currentWeekLoading, currentWeekError, currentUserChallenge, circumference])

  // If user challenge data is not ready, render nothing
  if (!currentUserChallenge) return null

  const isFlex = currentUserChallenge?.weeklyCommitment && currentUserChallenge.weeklyCommitment > 0

  let centerText = ''

  if (!isFlex) {
    //start date of the user challenge using the users's timezone
    const startDate = moment(currentUserChallenge.startDate)
    // days since start date
    const nowInTz = moment(new Date())
    const daycount = startDate.diff(nowInTz, 'days') + 1

    centerText = `Day ${daycount}`
  } else {
    centerText = `Week ${currentWeekData}`
  }
  if (currentWeekLoading) return null

  return (
    <svg className={styles.svg} width={size} height={size}>
      <defs>
        <linearGradient id="linearColors" x1="1" y1="0" x2="0" y2="1">
          <stop offset="0%" stopColor="#E42A6C"></stop>
          <stop offset="50%" stopColor="#C393FF"></stop>
          <stop offset="75%" stopColor="#C393FF"></stop>
          <stop offset="100%" stopColor="#C393FF"></stop>
        </linearGradient>
      </defs>
      <circle className={styles.svgCircleBg} cx={center} cy={center} r={radius} strokeWidth={strokeWidth} />
      <circle
        className={styles.svgCircle}
        ref={circleRef}
        cx={center}
        cy={center}
        r={radius}
        stroke="url(#linearColors)"
        strokeWidth={strokeWidth}
        strokeDasharray={circumference}
        strokeDashoffset={offset}
        transform={`rotate(-90 ${center} ${center})`}
        strokeLinecap="round"
      />
      <text x="50%" y="50%" dy=".3em" className={styles.svgCircleText}>
        {centerText}
      </text>
    </svg>
  )
}
