import React, { useContext, useState } from 'react'
import { UserChallengeContext } from '../../context'
import { useUserAchievements } from '../../hooks/useUserAchievements'
import { useAchievements } from '../../hooks/useAchievements'
import { Achievement } from '../../types/index'
import { useEffect } from 'react'
import { AuthStateContext } from '../../context'
import AchievementProgress from './AchievementProgress'
import { OptInAchievement } from './OptInAchievement'
import { useReportableUACs } from '../../hooks/useReportableUACs'
import { ReportList } from './ReportList'
import { useHistory } from 'react-router'
import AchievementsApi from '../../services/api/achievements'
import getAssociatedAchievements from '../../services/getAssociatedAchievements'

const TodayAchievements = () => {
  const { isLoading: reportableUacsLoading, doneReportingForYesterday, reportableUACs } = useReportableUACs()
  const { state: authState } = useContext(AuthStateContext)
  const [userOptedIn, setUserOptedIn] = useState(true)
  const { state: userChallengeContextState } = useContext(UserChallengeContext)
  const { activeChallenge } = userChallengeContextState
  const Achievements = useAchievements()
  const { userAchievements, userJoinedAchievement, createUserAchievement, isLoading } = useUserAchievements()
  const [displayedAchievement, setDisplayedAchievement] = useState<null | Achievement>(null)
  const [alertDismissed, setAlertDismissed] = useState<boolean | null>(null)
  const [hasYesterdayReportableUACs, setHasYesterdayReportableUACs] = useState<boolean>(false)
  const [dismissalStorageKey, setDismissalStorageKey] = useState<string>('')
  const [showOptIn, setShowOptIn] = useState<boolean | null>(null)
  const history = useHistory()

  useEffect(() => {
    let isCancelled = false
    if (isCancelled) return
    const checkAssociatedAchievement = async () => {
      console.log(`%c Checking for Associated :>> `, `color: red`)
      if (!activeChallenge || Achievements.isLoading) return
      try {
        console.log({ activeChallenge })
        const associatedAchievement = await activeChallengeAssociatedAchievement()
        console.log(`%c associatedAchievement :>> `, `color: red`, associatedAchievement)
        if (!associatedAchievement || !associatedAchievement.id) return
        const alreadyOptedIn = userAlreadyOptedInToAchievement(associatedAchievement.id)
        console.log({ alreadyOptedIn })
        if (!activeChallenge || !associatedAchievement) return
        setUserOptedIn(alreadyOptedIn)
        setDisplayedAchievement(associatedAchievement)
        setDismissalStorageKey(`LS/achievement/alertDismissed-${activeChallenge.id}-${associatedAchievement.id}`)
        if (alertDismissed === true) return
        if (userOptedIn === true) return
        if (displayedAchievement === null) return
        if (dismissalStorageKey === '') return
        console.log(`%c dismissalStorageKey :>> `, `color: red`, dismissalStorageKey)
        setAlertDismissed(localStorage.getItem(dismissalStorageKey) === 'true')
        console.log(`%c alertDismissed :>>`, localStorage.getItem(dismissalStorageKey) === 'true')
      } catch (error) {
        console.error('Error in checkAssociatedAchievement:', error)
      }
    }
    checkAssociatedAchievement()

    return () => {
      isCancelled = true
    }
  }, [activeChallenge, Achievements.isLoading, userAchievements, alertDismissed, userOptedIn, dismissalStorageKey])

  useEffect(() => {
    //guard against null values
    if (dismissalStorageKey === '') return
    if (userOptedIn === null) return
    if (alertDismissed === null) return
    if (!displayedAchievement) return

    console.log({ dismissalStorageKey })
    console.log({ userOptedIn })
    console.log({ alertDismissed })
    console.log({ displayedAchievement })
    if (alertDismissed === true) {
      setShowOptIn(false)
    } else if (userOptedIn === false && alertDismissed === false) {
      setShowOptIn(true)
    }
  }, [dismissalStorageKey, userOptedIn, alertDismissed])

  useEffect(() => {
    if (reportableUacsLoading) return
    const hasYesterdayReportableUACs = reportableUACs.yesterday.length > 0
    setHasYesterdayReportableUACs(hasYesterdayReportableUACs)
  }, [reportableUacsLoading, reportableUACs])

  useEffect(() => {
    showProgress = !showReportList && !showOptIn && userAchievements.size > 0
    showReportList = hasYesterdayReportableUACs && !doneReportingForYesterday
  }, [hasYesterdayReportableUACs, doneReportingForYesterday, userOptedIn, alertDismissed, displayedAchievement, userAchievements])

  const activeChallengeAssociatedAchievement = async () => {
    console.log(`%c activeChallenge :>> `, `color: red`, activeChallenge)
    if (!activeChallenge) return null
    const challengeId = activeChallenge.challenge.id
    console.log(`%c challengeId :>> `, `color: red`, challengeId)
    const associatedAchievement = await AchievementsApi.getAssociatedAchievementsForChallenge(challengeId)
    console.log(`%c associatedAchievement :>> `, `color: red`, associatedAchievement)
    if (!associatedAchievement) return null
    const result = associatedAchievement[0]
    console.log(`%c result :>> `, `color: red`, result)
    if (!result) return null
    return result
  }

  const userAlreadyOptedInToAchievement = (achievementId: string) => {
    return userJoinedAchievement(achievementId)
  }

  const dismissAlert = () => {
    console.log(`dismissAlert for ${displayedAchievement?.id} while in UC ${activeChallenge?.id}`)
    localStorage.setItem(dismissalStorageKey, 'true')
    setAlertDismissed(true)
  }

  const optInToAchievement = async () => {
    if (!displayedAchievement || !activeChallenge) return
    const newAchievement = {
      achievementId: displayedAchievement.id!,
      userChallengeId: activeChallenge.id,
      userId: authState.user!.id!,
      status: 'in-progress',
      starRating: 0,
      completed: false,
    }

    if (isLoading) return

    await createUserAchievement(newAchievement)
    setUserOptedIn(true)
  }

  let showReportList = hasYesterdayReportableUACs && !doneReportingForYesterday
  showOptIn
  console.log(
    `%c !showReportList , !!displayedAchievement && !userOptedIn && !alertDismissed  :>> `,
    `color: blue`,
    !showReportList,
    !!displayedAchievement && !userOptedIn && !alertDismissed,
  )

  let showProgress = !showReportList && !showOptIn && userAchievements.size > 0
  console.log(`%c showReportList , showInvite,  showProgress  :>> `, `color: red`, showReportList, showOptIn, showProgress)
  console.log(`%c showReportList , showInvite,  showProgress  :>> `, `color: red`, showReportList, showOptIn, showProgress)
  console.log('activeChallenge:', !!activeChallenge)
  console.log('displayedAchievement:', !!displayedAchievement)

  if (showReportList) console.log('Rendering ReportList')
  if (showOptIn) console.log('Rendering OptInAchievement')
  if (showProgress) console.log('Rendering AchievementProgress')

  return (
    <div className="  w-full mt-4 ">
      {showReportList && <ReportList yesterdayReportables={reportableUACs.yesterday} />}

      {showOptIn && displayedAchievement && (
        <OptInAchievement achievement={displayedAchievement} onOptIn={optInToAchievement} dismissable={true} onDismiss={dismissAlert} isAssociated={true} />
      )}
      {showProgress &&
        userAchievements.size > 0 &&
        Array.from(userAchievements.values()).map((userAchievement) => {
          const relatedAchievement = Achievements.findById(userAchievement.achievementId)
          // if the achievement has been completed for > 3 days then don't show it
          if (userAchievement.completed && userAchievement.endDate && new Date(userAchievement.endDate).getTime() < Date.now() - 3 * 24 * 60 * 60 * 1000) {
            console.log('userAchievement is old/completed not rendering :>> ', userAchievement.endDate)
            return null
          }
          if (!relatedAchievement) return null
          console.log(' attempting to render userAchievement :>> ', userAchievement)

          return <AchievementProgress key={userAchievement.id} achievement={relatedAchievement} userAchievement={userAchievement} dismissable={false} />
        })}
    </div>
  )
}

export default TodayAchievements
