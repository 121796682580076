import React from 'react'
import { NavLink } from 'react-router-dom'
import { Box } from 'shared/components'
import styles from './TopNavigation.module.scss'
import Logo from 'assets/images/leansquad-logo-black.png'
import { ReactComponent as Menu } from 'assets/icons/menu.svg'
import { ReactComponent as Close } from 'assets/icons/close-x.svg'

type TopNavigationProps = {
  toggleSidebar: (toggle: boolean) => void
  showSidebar: boolean
}

export const TopNavigation: React.FC<TopNavigationProps> = ({ toggleSidebar, showSidebar }) => {
  return (
    <Box className={styles.container}>
      <Box className={styles.navBar}>
        <Box className={styles.content}>
          <NavLink to="/" onClick={() => toggleSidebar(true)}>
            <img src={Logo} className={styles.logo} />
          </NavLink>
          <button onClick={() => toggleSidebar(false)}>
            {showSidebar ? (
              <>
                <Close data-testid="close-icon" className={styles.close} />
              </>
            ) : (
              <>
                <Menu data-testid="menu-icon" className={styles.menu} />
              </>
            )}
          </button>
        </Box>
      </Box>
    </Box>
  )
}
